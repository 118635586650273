/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import axios from "axios";
import LCFQheader from "../LCFQheader";
import FwFwbk from "../../common/fw/FwFwbk";
import Tzry from "../../common/fw/Tzry";
import FwTzrySelect from "../../common/fw/fwTzrySelect";
// import MyTableInfo from "./../../../components/common/Amycommon/MyTableInfo"
import { RxUtil } from "@/assets/util.js";
import { timesFnt, format, formatHMT } from "@/assets/app.js";
export default {
  name: "lcfqWjgs",
  components: {
    LCFQheader,
    FwFwbk,
    Tzry,
    FwTzrySelect
    // MyTableInfo,
  },

  data() {
    return {
      oneft: "/index",
      headTit: "新建申请",
      tit: "文件公示记录单",
      //userSelect
      single: false,
      allowSelect: true,
      showUserDialog: false,
      showDataFw: false,
      minDate: new Date(2020, 0, 1),
      currentDate: new Date(),
      showListpage: false,
      showRyDelect: false,
      showWordpage: false,
      showGinfo: false,
      userID: "",
      solId: "",
      actDefId: "",
      boDefId: "",
      formKey: "",
      //输入框
      wbHtmlPro: "",
      rysList: [],
      fjsc: [],
      userid: "",
      data: {
        ngr: "",
        fj: []
      }
    };
  },
  created() {
    // this.cgORxj();
    this.initBySolInstId();
    this.getUser();
  },
  methods: {
    initBySolInstId() {
      let solId = this.$route.params.solId;
      var url = _baseUrl + "/mobile/bpm/startForm.do?solId=" + solId;
      this.$ajax.post(url).then(response => {
        this.solId = response.data.bpmSolution.solId;
        this.actDefId = response.data.bpmSolution.actDefId;
        this.boDefId = response.data.formModels[0].boDefId;
        this.formKey = response.data.formModels[0].formKey;
      });
    },
    async cgORxj() {
      let solId = this.$route.params.solId;
      let busad = this.$route.params.busad;
      if (typeof busad != "undefined") {
        this.headTit = "我的草稿";
        this.oneft = "/myDrafts";
        var url = _baseUrl + "/mobile/bpm/startForm.do?solId=" + solId + "&busad=" + busad;
        this.$ajax.post(url).then(res => {
          this.solId = res.data.bpmSolution.solId;
          this.actDefId = res.data.bpmSolution.actDefId;
          this.boDefId = res.data.formModels[0].boDefId;
          this.formKey = res.data.formModels[0].formKey;
          this.data = res.data.formModels[0].jsonData;
          this.data.yzty = [];
        });
      } else {
        this.initBySolInstId();
        // this.getUser();
      }
    },

    //获取用户信息
    getUser() {
      let ngrId = RxUtil.getCache("userID");
      let ngrName = RxUtil.getCache("userName");
      let ngr = [{
        id: ngrId,
        text: ngrName
      }];
      this.data.ngr = JSON.stringify(ngr);
    },
    //选人
    selectUserDialog(vm) {
      this.showUserDialog = true;
      this.$refs.sysUser.search();
      // 设置选择用户。
      this.$refs.sysUser.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeUserVm = vm;
    },
    selectUser(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        o.id = obj.userId;
        o.text = obj.fullname;
        list.push(o);
      }
      var json = JSON.stringify(list);
      this.nodeUserVm.setValue(json);
      this.showUserDialog = false;
    },
    showWord() {
      this.showWordpage = true;
    },
    wbHtml(item) {
      this.wbHtmlPro = item;
      this.showWordpage = false;
      console.log(this.wbHtmlPro);
    },
    onConfirmFw(timestamp) {
      this.data.fwsj = format(timestamp);
      this.showDataFw = false;
    },
    showList() {
      this.showListpage = true;
    },
    //上传附件
    beforeRead(file) {
      if (this.data.fj.length > 0) {
        let fjStr = this.onFile();
        if (fjStr.indexOf(file.name) == -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    afterRead(file) {
      let formData = new FormData();
      formData.append("from", "SELF");
      formData.append("types", "");
      formData.append("sysTreeId", "");
      formData.append("id", "WU_FILE_0");
      formData.append("name", file.file.name);
      formData.append("type", file.file.type);
      formData.append("lastModifiedDate", file.file.lastModifiedDate);
      formData.append("size", file.file.size);
      formData.append("file", file.file);
      var fjObj;
      let formDataUrl = _baseUrl + "/sys/core/file/upload.do";
      this.$ajax.post(formDataUrl, formData).then(res => {
        fjObj = {};
        fjObj.fileId = res.data.data[0].fileId;
        fjObj.fileName = res.data.data[0].fileName;
        fjObj.totalBytes = res.data.data[0].totalBytes;
        this.fjsc.push(fjObj);
      });
    },
    beforeDelete(file) {
      if (this.data.fj.length > 1) {
        for (let i = 0; i < this.fjsc.length; i++) {
          if (file.file.name == this.fj[i].fileName) {
            this.fj.splice(i, 1);
          }
        }
        return true;
      } else {
        return true;
      }
    },
    onFile() {
      let fjObj = {};
      let fj = [];
      this.data.fj.map(item => {
        fjObj = {};
        fjObj.fileId = item.file.lastModified;
        fjObj.fileName = item.file.name;
        fjObj.totalBytes = item.file.size;
        fj.push(fjObj);
      });
      return JSON.stringify(fj);
    },
    datas() {
      var fwr = JSON.parse(this.data.fwr);
      // var wjtznr = JSON.stringify(this.wbHtmlPro);
      let fj = JSON.stringify(this.fjsc);
      var jsonDataObj = {
        bos: [{
          boDefId: this.boDefId,
          formKey: this.formKey,
          data: {
            fwr: fwr[0].id,
            fwr_name: fwr[0].text,
            fwsj: formatHMT(this.data.fwsj),
            wjtzmc: this.data.wjtzmc,
            wjtznr: this.wbHtmlPro,
            tzry: this.data.tzry,
            fj: fj,
            fffs: this.data.fffs,
            ffsj: formatHMT(this.data.ffsj),
            bz: this.data.bz,
            userid: this.userid
          }
        }]
      };
      console.log(jsonDataObj);
      var jsonData = JSON.stringify(jsonDataObj);
      var params = new FormData();
      params.append("solId", this.solId);
      params.append("bpmInstId", "");
      params.append("jsonData", jsonData);
      return params;
    },
    //字段检查
    iN(str) {
      if (str == '' || str == null || typeof str == 'undefined') {
        return true;
      }
      return false;
    },
    //整体判断
    inspect() {
      if (this.iN(this.data.fwr_name) || this.iN(this.data.wjmc) || this.iN(this.wbHtmlPro) || this.iN(this.data.rq)) {
        return true;
      }
      return false;
    },
    onSubmit() {
      if (this.inspect()) {
        this.errorToast("启动失败！请检查信息是否填写完整", "1800");
        return;
      }
      return;
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/startProcess.do";
      this.$dialog.loading.open('正在启动流程,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("启动成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("启动失败！" + data.data, "1800");
        }
      });
    },
    onPreservation() {
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/saveDraft.do";
      this.$dialog.loading.open('正在保存草稿,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("保存成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("保存失败！" + data.data, "1800");
        }
      });
    },
    onLct() {
      this.$router.push({
        name: "procImage",
        params: {
          id: this.actDefId,
          type: 'start'
        }
      });
    }
  }
};